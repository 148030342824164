<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <template>
          <v-card>
            <v-data-table
              :headers="product_list_header"
              :items="group_product_list"
              :search="search_product"
              :loading="loading"
              :server-items-length="totalItems1"
               hide-default-footer
              loading-text="Please wait..."
              item-key="id"
              class="elevation-0"
              style="background: #f5f5f5"
            >
              <template v-slot:top>
                <v-toolbar
                  elevation="2"
                  style="background: #f5f5f5; font-size: 26px"
                >
                  <v-toolbar-title
                    style="margin-left: -14px"
                    class="mt-2 mb-2 ml-2"
                  >
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="pa-5">
                    <v-text-field
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                      v-model="search_product"
                    ></v-text-field>
                  </div>
                  <div class="pa-5"></div>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
              <template v-slot:item.specification="{ item }">
                <v-btn
                  shaped
                  text
                  @click="viewSpecification(item)"
                  color="green"
                >
                  <v-icon dark> mdi-plus</v-icon>
                  Specification
                </v-btn>
              </template>
              <template v-slot:item.review="{ item }">
                <v-btn
                  shaped
                  text
                  @click="viewReview(item)"
                  color="blue lighten-2"
                >
                  <v-icon dark class="pr-2"> mdi-eye-outline</v-icon>
                  Review
                </v-btn>
              </template>
              <template v-slot:item.comments="{ item }">
                <v-btn
                  shaped
                  text
                  @click="viewComments(item)"
                  color="blue lighten-2"
                >
                  <v-icon dark class="pr-2"> mdi-eye-outline</v-icon>
                  Questions
                </v-btn>
              </template>
              <template v-slot:item.link="{ item }">
                <v-btn shaped text @click="viewLink(item)" color="#FF7743">
                  <v-icon class="mr-2" dark> mdi-link</v-icon>
                  Link
                </v-btn>
              </template>
              <template v-slot:item.status="{ item }">
                <v-btn
                  text
                  small
                  ripple
                  color="red"
                  v-if="item.product_status == 'Published'"
                  @click="action(item)"
                >
                  Unpublish</v-btn
                >
                <v-btn
                  text
                  small
                  ripple
                  color="primary"
                  v-else
                  @click="action(item)"
                >
                  Publish</v-btn
                >
              </template>
            </v-data-table>
              <div class="d-flex justify-content-center mt-5">
                <!-- <v-col cols="3" md="3"> -->
                <div class="col-4">
                    Total {{ totalItems1 }} records
                </div>
                <!-- </v-col> -->
                <!-- <v-col cols="3" md="3"> -->
                <div class="col-4">
                    <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
                    </v-select>                                                
                </div>
                <!-- </v-col> -->
                <!-- <v-col cols="6" md="3" > -->
                <div class="col-4">
                    <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
                    </v-pagination>
                </div>
                <!-- </v-col> -->
              </div>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      perPage1: '10',
      currentPage1: 1,
      totalItems1: 0,
      totalPages1: 0,
      pageSizes1: ['10', '30', '50', '100', 'All'],
      search_product: "",
      product_list_header: [
        { text: "ID", value: "id", sortable: false },
        { text: "Title", value: "title" },
        { text: "Brand", value: "brand", sortable: false },
        { text: "No.Spec", value: "specific_status", sortable: false },
        { text: "", value: "specification", sortable: false },
        { text: "", value: "status", sortable: false },
        { text: "", value: "review", sortable: false },
        { text: "", value: "comments", sortable: false },
        // { text: "", value: "link", sortable: false },
        // { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      group_product_list: [],
    };
  },
  methods: {

    handlePageSizeChange(event) {
      this.perPage1 = (event);
      this.loading = true;
      this.created();
    },
    handlePageChange(value) {
      this.currentPage1 = value;
      this.loading = true;
      this.created();
      window.scrollTo(0, 0);
    },

    viewSpecification(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/specification/${item.id}`,
      });
      routeData.href = `/dashboard/pages/specification/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewReview(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/review/${item.id}`,
      });
      routeData.href = `/dashboard/pages/review/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewComments(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/viewcomments/${item.id}`,
      });
      routeData.href = `/dashboard/pages/viewcomments/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    viewLink(item) {
      let routeData = this.$router.resolve({
        name: `https://mymarket.com.bd/products/${item.title.replaceAll(
          " ",
          "-"
        )}/details/?spm=${item.id}`,
      });
      routeData.href = `https://mymarket.com.bd/products/${item.title.replaceAll(
        " ",
        "-"
      )}/details/?spm=${item.id}`;
      window.open(routeData.href, "_blank");
    },
  },
  created() {
    // this.loading = true;
    // product/show_group_products
    // product_details
    let perPage ;
      if (this.perPage1 === 'All'){
          perPage = this.totalItems1;
      }
      else
      {
          perPage = this.perPage1;
      }
      // console.log('add warehouse method called');
      this.loading = true;
    axios
      .get(`product/show_group_products/?page=${this.currentPage1}&size=${perPage}`)
      .then((response) => {
        if(response.status === 200){
          // console.log('All group products data is :', response.data.results);
          this.group_product_list = response.data.results;
          // console.log('all products data is:', );
          this.totalItems1 = response.data.count;
          this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
          this.loading = false;
        }
        // console.log(response.data);
        // this.loading = false;
        // this.group_product_list = response.data.data
      })
      .catch((err) => {
        this.loading = false;
      });
  },
};
</script>

<style>
</style>