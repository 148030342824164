var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[[_c('v-card',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.product_list_header,"items":_vm.group_product_list,"search":_vm.search_product,"loading":_vm.loading,"server-items-length":_vm.totalItems1,"hide-default-footer":"","loading-text":"Please wait...","item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5","font-size":"26px"},attrs:{"elevation":"2"}},[_c('v-toolbar-title',{staticClass:"mt-2 mb-2 ml-2",staticStyle:{"margin-left":"-14px"}}),_c('v-spacer'),_c('div',{staticClass:"pa-5"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search_product),callback:function ($$v) {_vm.search_product=$$v},expression:"search_product"}})],1),_c('div',{staticClass:"pa-5"}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.specification",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"green"},on:{"click":function($event){return _vm.viewSpecification(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus")]),_vm._v(" Specification ")],1)]}},{key:"item.review",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"blue lighten-2"},on:{"click":function($event){return _vm.viewReview(item)}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"dark":""}},[_vm._v(" mdi-eye-outline")]),_vm._v(" Review ")],1)]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"blue lighten-2"},on:{"click":function($event){return _vm.viewComments(item)}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"dark":""}},[_vm._v(" mdi-eye-outline")]),_vm._v(" Questions ")],1)]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"#FF7743"},on:{"click":function($event){return _vm.viewLink(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v(" mdi-link")]),_vm._v(" Link ")],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.product_status == 'Published')?_c('v-btn',{attrs:{"text":"","small":"","ripple":"","color":"red"},on:{"click":function($event){return _vm.action(item)}}},[_vm._v(" Unpublish")]):_c('v-btn',{attrs:{"text":"","small":"","ripple":"","color":"primary"},on:{"click":function($event){return _vm.action(item)}}},[_vm._v(" Publish")])]}}])}),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('div',{staticClass:"col-4"},[_vm._v(" Total "+_vm._s(_vm.totalItems1)+" records ")]),_c('div',{staticClass:"col-4"},[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","label":"Items per page","items":_vm.pageSizes1},on:{"change":_vm.handlePageSizeChange},model:{value:(_vm.perPage1),callback:function ($$v) {_vm.perPage1=$$v},expression:"perPage1"}})],1),_c('div',{staticClass:"col-4"},[_c('v-pagination',{attrs:{"length":_vm.totalPages1,"total-visible":"7"},on:{"input":_vm.handlePageChange},model:{value:(_vm.currentPage1),callback:function ($$v) {_vm.currentPage1=$$v},expression:"currentPage1"}})],1)])],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }